import React from 'react';
import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer';
import SearchModal from '../../components/Layout/Header/SearchModal';
import ContactMain from './ContactMain';


const Quote = () => {
  return (
    <React.Fragment>
      <Header parentMenu='home' secondParentMenu='multipage' activeMenu='/home-1' />
      
      <ContactMain />

      <Footer footerClass='rs-footer' />
      {/* <SearchModal /> */}
    </React.Fragment>
  );
};

export default Quote;
