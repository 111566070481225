import React from 'react';
import BannerDefault from '../../components/Banner/BannerDefault';
import ScrollToTop from '../../components/Common/ScrollTop';

import Blog from './BlogSection';


const HomeMain = () => {
  return (
    <React.Fragment>
      {/* banner-start */}
      <BannerDefault />
      {/* banner-start */}

      <Blog />
      
      
      {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', width: '100%' }}> */}
      
      
      
      
      {/* </div> */}
      {/* about-area-start */}

      {/* scrolltop-start */}
      <ScrollToTop />
      {/* scrolltop-end */}
    </React.Fragment>
  );
};

export default HomeMain;
