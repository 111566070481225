import React from 'react';
import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer';
import Service1 from './Service1Section';

const Services = () => {
  return (
    <React.Fragment>
      <Header parentMenu='home' secondParentMenu='multipage' activeMenu='/home-1' />
      
      <Service1 />
      
      <Footer footerClass='rs-footer' />
      {/* <SearchModal /> */}
    </React.Fragment>
  );
};

export default Services;
