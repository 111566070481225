import React from 'react';
import SectionTitle from '../../components/Common/SectionTitle';

import bgImg from '../../assets/img/bg/shape-bg2.png';

const bgStyle = {
  backgroundImage: `url(${bgImg})`,
};

const AboutUs2 = () => {
  return (
    <div id='rs-about' className='rs-specialty pt-170 pb-230 md-pb-184' style={bgStyle}>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-lg-6 pl-54 md-pl-14 md-pt-60'>
            <SectionTitle
              sectionClass='sec-title6 mb-30'
              subtitleClass='sub-text gold-color'
              subtitle=''
              titleClass='title pb-20'
              title='Your One-Stop Shop for Electronic Components'
            />
            <p>
              Sonna is a fast-paced electronic component supplier dedicated to providing exceptional service while maintaining manufacturing
              excellence and reliability. Whenever you need hard-to-find electrical parts urgently due to shortages, extended production time,
              expiration, or manufacturing issues, turn to Sonna for more excellent responsiveness and supply.
              <br />
              <br />
              We provide a supply chain and take pride in providing excellent reliability, competitive price, and productive and efficient shipping.
              We're committed to our client fulfillment and assist throughout the production process, from conception to obsolescence.
            </p>
          </div>
          <img src={require('./../../assets/img/real/service-1.jpg').default} className='col-lg-6' style={{ padding: 0, borderRadius: 20 }} />
        </div>
      </div>
    </div>
  );
};

function CheckItem({ title }) {
  return (
    <div style={{ display: 'flex', flexDirection: 'row' }} className='ml-24 mr-24'>
      <input class='form-check-input' type='checkbox' value='' id='flexCheckChecked' checked />
      <p>{title}</p>
    </div>
  );
}

export default AboutUs2;
