import React from 'react';
import SectionTitle from '../../components/Common/SectionTitle';

import bgImg from '../../assets/img/bg/shape-bg2.png';

const bgStyle = {
  backgroundImage: `url(${bgImg})`,
};

const AboutUs1 = () => {
  return (
    <div id='rs-about' className='rs-specialty pt-170 pb-230 md-pb-184' style={bgStyle}>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-lg-6 pl-54 md-pl-14 md-pt-60'>
            <SectionTitle
              sectionClass='sec-title6 mb-30'
              subtitleClass='sub-text gold-color'
              subtitle=''
              titleClass='title pb-20'
              title='Sonna - Prepare or Design, Sourcing, Manufacturing, and Delivered'
            />
            <p>
              Sonna can assist you with anything! Our professionals are not just delivering the electrical components. We offer the most appropriate
              solutions for production and manufacturing businesses to stand out in this competitive market. We provide tailor-made versatility
              solutions throughout your organization, from international markets to supplier and shipment coordination. We deliver a competitive edge
              to consumers and improve the moment, sustainability, and effectiveness through our experience in quality improvement, supplier
              management, and innovation
            </p>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <CheckItem title='Defense Sector' />
                <CheckItem title='Medical Sector' />
                <CheckItem title='IT Sector' />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <CheckItem title='Automotive Sector' />
                <CheckItem title='Consumer Electronic Sector' />
                <CheckItem title='Aerospace Sector' />
              </div>
            </div>
          </div>
          <img src={require('./../../assets/img/real/aboutus-1.jpg').default} className='col-lg-6' style={{ padding: 0, borderRadius: 20 }} />
          {/* <div className='col-lg-6'>
            <div className='rs-feature'>
              <div className='row'>
                <div className='col-md-6 mt-20 pr-6 md-pb-20 sm-pr-14'>
                  <img src={require('./../../assets/img/real/feature-2.jpg').default} className='icon-box-area purple-bg' style={{ padding: 0 }} />
                </div>
                <div className='col-md-6'>
                  <img
                    src={require('./../../assets/img/real/feature-3.jpg').default}
                    className='icon-box-area blue-bg mb-20'
                    style={{ padding: 0 }}
                  />
                  <img src={require('./../../assets/img/real/feature-4.jpg').default} className='icon-box-area pink-bg' style={{ padding: 0 }} />
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

function CheckItem({ title }) {
  return (
    <div style={{ display: 'flex', flexDirection: 'row' }} className='ml-24 mr-24'>
      <input className='form-check-input' type='checkbox' value='' id='flexCheckChecked' checked style={{ marginTop: 8 }} />
      <p>{title}</p>
    </div>
  );
}

export default AboutUs1;
