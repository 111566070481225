import React from 'react';
import SectionTitle from '../../components/Common/SectionTitle';

import bgImg from '../../assets/img/bg/shape-bg2.png';

const bgStyle = {
  backgroundImage: `url(${bgImg})`,
};

const Service2 = () => {
  return (
    <div id='whychoose' className='rs-specialty pt-170 pb-30 md-pb-184' style={bgStyle}>
      <div className='container'>
        <div className='row align-items-center'>
          <img src={require('./../../assets/img/real/service-3.jpg').default} className='col-lg-6' style={{ padding: 0, borderRadius: 20 }} />
          <div className='col-lg-6 pl-54 md-pl-14 md-pt-60'>
            <SectionTitle
              sectionClass='sec-title6 mb-30'
              subtitleClass='sub-text gold-color'
              subtitle=''
              titleClass='title pb-20'
              title='Why you should choose Sonna'
            />
            <CheckItem title={'Immediate attention to your requirements'} />
            <CheckItem title={'Obtaining trustworthy or reliable components'} />
            <CheckItem title={'Utilize smart logistics for quick turnaround'} />
            <CheckItem title={'In terms of quality assurance, all responsibility is assumed.'} />
            <CheckItem title={'Advisor who speaks your local language'} />
            <CheckItem title={'Engineering Expertise'} />
            <CheckItem title={'Bridging Manufacturing and Purchase'} />
            <CheckItem title={'Product Support'} />
            <CheckItem title={'Huge Supplier Network'} />

          </div>
        </div>

        <div className='container pt-70 pb-30'>
        <div className='row'>
          <h4>Sonna prides itself in being considered the most reputed electronic component partner for organizations across the globe.</h4>
            <div className='col-lg-6 pl-54 md-pl-14 md-pt-60'>
              <div className='contact-widget'>
              <CheckItem title={'Dedicated Team Leads'} />
              <CheckItem title={'Project Dashboards'} />
              <CheckItem title={'Convenience'} />
              <CheckItem title={'High Quality Products'} />
              <CheckItem title={'Trust'} />
              <CheckItem title={'Line Card'} />
              <CheckItem title={'Laser Module'} />
              <CheckItem title={'Reliable'} />
              <CheckItem title={'Range of Products'} />
              <CheckItem title={'Inventory'} />
              </div>
            </div>

            <div className='col-lg-6 pl-54 md-pl-14 md-pt-60'>
              <div className='contact-widget'>
              <CheckItem title={'Experience'} />
              <CheckItem title={'People Focused'} />
              <CheckItem title={'Huge Inventory of Ready Stocks'} />
              <CheckItem title={'Creativity'} />
              <CheckItem title={'Quality Conscious'} />
              <CheckItem title={'Core Values'} />
              <CheckItem title={'Reputed'} />
              <CheckItem title={'Dedicated'} />
              </div>
              
              </div>
          </div>

      </div>
      </div>
      
          
       

    </div>
  );
};

function CheckItem({ title }) {
  return (
    <div style={{ display: 'flex', flexDirection: 'row' }} className='ml-24 mr-24'>
      <input className='form-check-input' type='checkbox' value='' id='flexCheckChecked' checked style={{ marginTop: 8 }} />
      <p>{title}</p>
    </div>
  );
}

export default Service2;
