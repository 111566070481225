import React from 'react';
import SectionTitle from '../../components/Common/SectionTitle';

import bgImg from '../../assets/img/bg/shape-bg2.png';

const bgStyle = {
  backgroundImage: `url(${bgImg})`,
};

const Feature = () => {
  return (
    <div id='rs-about' className='rs-specialty pt-170 pb-230 md-pb-184' style={bgStyle}>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-lg-6 pl-54 md-pl-14 md-pt-60'>
            <SectionTitle
              sectionClass='sec-title6 mb-30'
              subtitleClass='sub-text gold-color'
              subtitle=''
              titleClass='title pb-20'
              title='We deliver precisely what you want!'
            />
            <p>
              Sonna is the company to contact quickly whenever you need electronic components. We are honored to work with your company to address any
              issues you are currently experiencing and provide you with appropriate solutions suited to your needs. Our component expertise and
              competent professionals prioritize your needs and ensure that items are delivered on time.
              <br />
              <br />
              We are dedicated to forming long-term connections with our clients by providing them with exactly what they need to grow their business
              to the next level.
            </p>
          </div>
          <div className='col-lg-6'>
            <div className='rs-feature'>
              <div className='row'>
                <div className='col-md-6 mt-20 pr-6 md-pb-20 sm-pr-14'>
                  {/* <SingleFeature featureClass='icon-box-area mb-20' featureImage={featureIcon1} featureTitle='Experts Problem Solvers' /> */}
                  <img src={require('./../../assets/img/real/feature-1.jpg').default} className='icon-box-area mb-20' style={{ padding: 0 }} />
                  <img src={require('./../../assets/img/real/feature-2.jpg').default} className='icon-box-area purple-bg' style={{ padding: 0 }} />
                  {/* <SingleFeature featureClass='icon-box-area purple-bg' featureImage={featureIcon3} featureTitle='Creative Product Analitic' /> */}
                </div>
                <div className='col-md-6'>
                  <img
                    src={require('./../../assets/img/real/feature-3.jpg').default}
                    className='icon-box-area blue-bg mb-20'
                    style={{ padding: 0 }}
                  />
                  <img src={require('./../../assets/img/real/feature-4.jpg').default} className='icon-box-area pink-bg' style={{ padding: 0 }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feature;
