import React, { useState } from 'react'
import axios from 'axios';
import { useHistory } from "react-router-dom";

const ContactForm = (props) => {

  const history = useHistory();

  const { submitBtnClass } = props;

  const [cont_name, setcont_name] = useState();
   const [part_no, setpart_no] = useState();
   const [manufacturer, setmanufacturer] = useState();
   const [quantity_required, setquantity_required] = useState();
   const [cont_company, setcont_company] = useState();
   const [cont_email, setcont_email] = useState();
   const [cont_phone, setcont_phone] = useState();
   const [support_cycle, setsupport_cycle] = useState();
   const [remark, setremark] = useState();
   const [message, setmessage] = useState('');

   const handleSubmit = (event) =>{
      event.preventDefault();
      const inputField = {cont_name:cont_name, cont_company:cont_company, cont_email:cont_email, cont_phone:cont_phone, support_cycle:support_cycle, part_no:part_no, manufacturer:manufacturer, quantity_required:quantity_required, remark:remark, website_id:'1096' };
      console.log(inputField);
      const API_PATH = 'http://54.145.7.92/sendemail/common_react.php';

      axios({
         method: 'POST',
         url: `${API_PATH}`,
         headers: { 'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8' },
         async: true,
         data: JSON.stringify(inputField),
         //datatype: 'json',
         mimeType: "text/html; charset=utf-8",
         mode: 'no-cors'
      })
      .then(response => {
         if(response.data == 1){
          history.push('/thanks');
         }
       })
       .catch(error => {
           console.error("Error:", error);
           setmessage('error in email sending');
           console.log({message});
       });

     }; 

  return (
    <form id='contact-form' onSubmit={handleSubmit}>
      <div className='row'>
        <div className='col-md-6 mb-30'>
          <input className='from-control' type='text'  name='cont_name' onChange={e => setcont_name(e.target.value)} value={cont_name} placeholder='Your Name' required />
        </div>

        <div className='col-md-6 mb-30'>
          <input className='from-control' type='text' name='cont_company' onChange={e => setcont_company(e.target.value)}  value={cont_company} placeholder='Company*' required />
        </div>

        <div className='col-md-6 mb-30'>
          <input className='from-control' type='text' name='cont_email' onChange={e => setcont_email(e.target.value)} value={cont_email} placeholder='Mail Address*' required />
        </div>
        <div className='col-md-6 mb-30'>
          <input className='from-control' type='text' name='cont_phone' onChange={e => setcont_phone(e.target.value)} value={cont_phone} placeholder='Phone*' required />
        </div>
        <div className='col-md-6 mb-30'>
          <input className='from-control' type='text' name='part_no' onChange={e => setpart_no(e.target.value)}  value={part_no} placeholder='Part No*' required />
        </div>
        <div className='col-md-6 mb-30'>
          <input className='from-control' type='text' name='manufacturer' onChange={e => setmanufacturer(e.target.value)} value={manufacturer} placeholder='MFR*' required />
        </div>
        <div className='col-md-6 mb-30'>
          <input className='from-control' type='text' name='quantity_required' onChange={e => setquantity_required(e.target.value)} value={quantity_required} placeholder='QTY*' required />
        </div>
        <div className='col-md-6 mb-30'>
          <input className='from-control' type='text' name='support_cycle' onChange={e => setsupport_cycle(e.target.value)} value={support_cycle} placeholder='What stage of the cycle do you need support for?' />
        </div>

        <div className='col-12 mb-30'>
          <textarea className='from-control' id='remark' name='remark' onChange={e => setremark(e.target.value)} value={remark} placeholder='Your message*' required></textarea>
        </div>
      </div>
      <div className='btn-part'>
        <button className={submitBtnClass ? submitBtnClass : 'readon learn-more submit'} type='submit'>
          Submit Now
        </button>
      </div>
    </form>
  );
};

export default ContactForm;
