import React from 'react';
import SectionTitle from '../../components/Common/SectionTitle';
import ContactForm from '../../components/Contact/ContactForm';

const ContactMain = () => {
  return (
    <React.Fragment>
      {/* SiteBreadcrumb */}

      {/* SiteBreadcrumb */}

      {/* Contact Section Start */}
      <div className='rs-contact pt-120 md-pt-80 mb-100' id='contact'>
        <div className='container'>
          <div className='row'>
            <div className='pl-70 pr-70 md-pl-14'>
              <div className='contact-widget'>
                <SectionTitle
                  sectionClass='sec-title2 mb-40'
                  subtitleClass='sub-text contact mb-14'
                  subtitle={"We'll contact you as soon as we receive your needs!"}
                  titleClass='title testi-title'
                  title='Get Your Quote Today'
                />

                {/* Contact Form */}
                <ContactForm />
                {/* Contact Form */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Contact Section End */}
    </React.Fragment>
  );
};

export default ContactMain;
