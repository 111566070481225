import React from 'react';
import { Link } from 'react-router-dom';

import Logo from '../../../assets/img/real/logo.png';

const TopHeader = () => {
  return (
    <div className='topbar-area hidden-md'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-2'>
            <div className='logo-part'>
              <Link to='/'>
                <img src={Logo} alt='Logo' style={{ height: 50 }} />
              </Link>
            </div>
          </div>
          <div className='col-lg-10 text-right'>
            <ul className='rs-contact-info'>
              <li className='contact-part'>
                <i className='flaticon-location'></i>
                <span className='contact-info'>
                  <span>Address</span>
                  TFC, Ak. 9 #11530, Bogotá, Colombia 110111
                </span>
              </li>
              <li className='contact-part'>
                <i className='flaticon-email'></i>
                <span className='contact-info'>
                  <span>E-mail</span>
                  <a href='mailto:connect@sonnatech.com'> connect@sonnatech.com</a>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopHeader;
