import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

//Custom Components

import LoadTop from '../components/Common/ScrollTop/LoadTop';
import Error from '../pages/404';
import Home from '../pages/home';
import About from '../pages/home/about';
import Services from '../pages/home/services';
import Why_us from '../pages/home/why_us';
import Quote from '../pages/home/quote';
import Thanks from '../pages/thanks';

const App = () => {
  return (
    <div className='App'>
      <Router>
        <LoadTop />
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/about' exact component={About} />
          <Route path='/services' exact component={Services} />
          <Route path='/why_us' exact component={Why_us} />
          <Route path='/quote' exact component={Quote} />
          <Route path='/thanks' exact component={Thanks} />
          <Route component={Error} />
        </Switch>
      </Router>
    </div>
  );
};

export default App;
