import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import AppointmentForm from '../Appointment';

const BannerDefault = () => {

    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(!isOpen);

    return (
        <React.Fragment>

            {/* <!-- banner section start --> */}
            <div className="rs-banner style1">
                <ModalVideo channel='youtube' isOpen={isOpen} videoId='vWLcyFtni6U' onClose={() => { openModal(); }} />
                <div className="banner-height pt-100 pb-100 md-pt-80 md-pb-80">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7 col-md-12 pt-40 pr-140 md-pt-0 md-mb-60 md-pr-14">
                                <div className="banner-content">
                                    <h2 className="bnr-title" data-animation="fadeInLeft" data-delay=".3s">Sonna  - A worldwide supplier of components</h2>
                                    <p className="bnr-desc" data-animation="fadeInLeft" data-delay=".5s">Looking for a reliable electronic component supplier? Sonna has global resources to assist in fulfilling your order today! Tell us your requirements and we will find the products available to best fit your needs.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- banner section end --> */}

        </React.Fragment>
    );
}

export default BannerDefault;