import React from 'react';
import SectionTitle from '../../components/Common/SectionTitle';

import bgImg from '../../assets/img/bg/shape-bg2.png';

const bgStyle = {
  backgroundImage: `url(${bgImg})`,
};

const Service1 = () => {
  return (
    <div id='services' className='rs-specialty pt-170 pb-230 md-pb-184' style={bgStyle}>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-lg-6 pl-54 md-pl-14 md-pt-60'>
            <SectionTitle
              sectionClass='sec-title6 mb-30'
              subtitleClass='sub-text gold-color'
              subtitle=''
              titleClass='title pb-20'
              title='Services we offer:'
            />
            <b>Excellent Quality Control</b>
            <p>
              Our production quality supervisors are experts in spotting defects in production and have a wide range of sophisticated testing tools
              and resources.
            </p>
            <b>Cost Savings And Worldwide Procurement</b>
            <p>
              We deliver tailored cost-cutting solutions and can give you products from some of the most inexpensive market parts, helping our
              consumers boost their profitability by reducing manufacturing costs.
            </p>
            <b>Management Of Shortage Situation</b>
            <p>
              Any event that could result in a commuter lot and a brief or prolonged interruption to your output is treated as a shortage concern by
              us.
            </p>
            <b>Assurance Of Quality</b>
            <p>Our objective is to maintain integrity, and our identity is established on our quality control standards.</p>
            <b>Contract Manufacturing</b>
            <p>Based on procedures, tools, labor, and material costs, Sonna will provide you with an estimate for any contract manufacturing service.</p>
            <b>24 x 7 Support</b>
            <p>We offer instant support to all our clients throughout the year.</p>
            <b>Machining & Tooling</b>
            <p>Our success as a mold maker and molder is predicated on the ability to mold and construct high-quality parts, which starts with well-designed, well-made, and well-maintained molds.</p>
            <b>Fast Logistics</b>
            <p>Consolidate your supply chain with Sonna to save money on shipping and storage.</p>
            <b>Reliable & Independent Distribution</b>
            <p>With us you get access to the best manufacturers globally.</p>
            <b>Technical Assistance</b>
            <p>Sonna’s team of experts can help you with technical requirements.</p>
          </div>
          <div className='col-lg-6'>
            <div className='rs-feature'>
              <div className='row'>
                <div className='col-md-6 mt-20 pr-6 md-pb-20 sm-pr-14'>
                  {/* <SingleFeature featureClass='icon-box-area mb-20' featureImage={featureIcon1} featureTitle='Experts Problem Solvers' /> */}
                  <img src={require('./../../assets/img/real/service-2-1.jpg').default} className='icon-box-area mb-20' style={{ padding: 0 }} />
                  <img src={require('./../../assets/img/real/service-2-2.jpg').default} className='icon-box-area purple-bg' style={{ padding: 0 }} />
                  {/* <SingleFeature featureClass='icon-box-area purple-bg' featureImage={featureIcon3} featureTitle='Creative Product Analitic' /> */}
                </div>
                <div className='col-md-6'>
                  <img
                    src={require('./../../assets/img/real/service-2-3.jpg').default}
                    className='icon-box-area blue-bg mb-20'
                    style={{ padding: 0 }}
                  />
                  <img src={require('./../../assets/img/real/service-2-4.jpg').default} className='icon-box-area pink-bg' style={{ padding: 0 }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Service1;
