import React from 'react';
import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer';
import SearchModal from '../../components/Layout/Header/SearchModal';
import Feature from './FeatureSection';
import AboutUs1 from './AboutUs1Section';
import SectionTitle from '../../components/Common/SectionTitle';
import AboutUs2 from './AboutUs2Section';

const About = () => {
  return (
    <React.Fragment>
      <Header parentMenu='home' secondParentMenu='multipage' activeMenu='/home-1' />
      
      <Feature />
      <AboutUs1 />

      <SectionTitle
        sectionClass='sec-title6 mb-30 text-center'
        subtitleClass='sub-text gold-color text-center'
        subtitle=''
        titleClass='title pb-10 text-center mr-80 ml-80'
        title="Regardless of the issues you're having with your electronic components, Sonna has you covered!"
        // description='Bring to the table win-win survival strategies to ensure dotted proactive domination. At the end of the day, going forward, a new normal that has evolved '
      />
      <p className='mr-80 ml-80 text-center'>
        Sonna will always give you lots of the assistance you require. We offer efficient inventory management solutions to minimize costs, wastage,
        and manufacturing delays. We evaluate the information from our suppliers to determine where we can make changes
      </p>
      <p className='mr-80 ml-80 text-center font-weight-bold'>For more inquiries, get in touch with our specialists right now!</p>
      <AboutUs2 />

      <Footer footerClass='rs-footer' />
      {/* <SearchModal /> */}
    </React.Fragment>
  );
};

export default About;
