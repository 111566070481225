import React from 'react';
import { Link } from 'react-router-dom';
import BlogPart from '../../components/Blog/HomeBlogSection';
import SectionTitle from '../../components/Common/SectionTitle';
import OwlCarousel from 'react-owl-carousel2';

const Blog = () => {
  const opts = {
    items: 3,
    nav: false,
    dots: true,
    margin: 30,
    rewind: false,
    autoplay: false,
    stagePadding: 30,
    loop: true,
    center: false,
    responsive: {
      0: { stagePadding: 0, items: 1, nav: false },
      768: { items: 2, stagePadding: 0 },
      992: { items: 2, stagePadding: 0 },
      1200: { items: 3, stagePadding: 0 },
      1500: { items: 3, stagePadding: 0 },
    },
  };
  return (
    <React.Fragment>
      <div className='rs-blog pt-108 pb-120 md-pt-70 md-pb-70' id='about'>
        <div className='container'>
          {/* <SectionTitle
            sectionClass='sec-title2 text-center mb-44'
            subtitleClass='sub-text'
            subtitle=''
            titleClass='title testi-title'
            title=''
            descClass='desc'
            description={
              "Sonna understands that our customers don't want to delay for their needs, and you want the best. We feel the same way about it. Dealing with a reputable electronic components vendor, such as ours, can increase your productivity, dependability, and lower company operational expenses. Almost every business and sector has an existing customers with us."
            }
            effectClass='heading-line'
          /> */}
          <p className='desc' style={{ textAlign: 'center' }}>
          At Sonna, we take pride in being a reputable electronic component vendor and we understand that efficient and fast sourcing is the key to customer satisfaction. We look to bring value to businesses and customers around the world with increased productivity, dependability, and lower company operational expenses.
          </p>
          <OwlCarousel options={opts}>
            {/* <BlogPart /> */}
            <SinglePost
              blogClass='blog-item'
              blogTitle='Defense Sector'
              blogDesc={
                "Sonna is associated with aircraft and works with several of the largest and most prestigious defense firms. For you, depreciation is a significant issue, but we're here to help."
              }
              blogImage={require('./../../assets/img/real/home-2-1.jpg')}
            />
            <SinglePost
              blogClass='blog-item'
              blogTitle='Medical Sector'
              blogDesc={
                'Our professionals understand that livelihoods are on the line, and we incorporate various quality standards in our component manufacturing or distributions for our medical profession clients.'
              }
              blogImage={require('./../../assets/img/real/home-2-2.jpg')}
            />
            <SinglePost
              blogClass='blog-item'
              blogTitle='IT Sector'
              blogDesc={
                "Everyone understands that we can't accomplish anything without the components, and we're here to help our clients with their IT networking and communication needs so they may get the finest service possible."
              }
              blogImage={require('./../../assets/img/real/home-2-3.jpg')}
            />
          </OwlCarousel>
          <OwlCarousel options={opts}>
            {/* <BlogPart /> */}
            <SinglePost
              blogClass='blog-item'
              blogTitle='Automotive Sector'
              blogDesc={
                "Transportation necessitates a steady supply of tough parts. We'll almost certainly possess, or be able to locate, whatever you need to maintain your business."
              }
              blogImage={require('./../../assets/img/real/home-2-4.jpg')}
            />
            <SinglePost
              blogClass='blog-item'
              blogTitle='Consumer Electronic Sector'
              blogDesc={
                'We can assist you in meeting the demands of technologies and the development customers. To help you increase performance without increasing expense, your higher production demands constant, firm supplies.'
              }
              blogImage={require('./../../assets/img/real/home-2-5.jpg')}
            />
            <SinglePost
              blogClass='blog-item'
              blogTitle='Aerospace Sector'
              blogDesc={
                'In aviation and commercial airline operations, high-quality equipment is constantly required, and we assist you by providing the best and superior components that meet your requirements.'
              }
              blogImage={require('./../../assets/img/real/home-2-6.jpg')}
            />
          </OwlCarousel>
        </div>
      </div>
    </React.Fragment>
  );
};

const SinglePost = (props) => {
  const { blogClass, blogImage, blogTitle, blogDesc, blogCategory, blogAuthor, blogPublishedDate, blogButtonClass, blogButtonText } = props;
  return (
    <div className={blogClass ? blogClass : 'blog-item mb-44'}>
      <div className='image-wrap'>
        <img src={blogImage?.default} alt={blogTitle} />
      </div>
      <div className='blog-content'>
        <h3 className='blog-title'>{blogTitle ? blogTitle : 'Open Source Job Report Show More Openings Fewer '}</h3>
        <p className='desc'>
          {blogDesc
            ? blogDesc
            : 'We denounce with righteous indige nation and dislike men who are so beguiled and demo realized by the charms of pleasure of the moment, so blinded by desire, that...'}
        </p>
      </div>
    </div>
  );
};

export default Blog;
