import React from 'react';
import { Link } from 'react-router-dom';

const FooterBottom = () => {
  return (
    <div className='footer-bottom'>
      <div className='container'>
        <div className='row y-middle'>
          <div className='col-lg-6 text-right md-text-center md-mb-10 order-last'></div>
          <div className='col-lg-6'>
            <div className='copyright md-text-center md-mb-10'>
              <p>© 2022 All Rights Reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterBottom;
