import React from 'react';
import Header from '../components/Layout/Header';
import Footer from '../components/Layout/Footer';


import SectionTitle from '../components/Common/SectionTitle';


const Thanks = () => {
  return (
    <React.Fragment>
      <Header parentMenu='home' secondParentMenu='multipage' activeMenu='/home-1' />

      <SectionTitle
        sectionClass='sec-title6 mt-30 text-center'
        subtitleClass='sub-text gold-color text-center'
        subtitle=''
        titleClass='title pt-10 text-center mr-80 ml-80'
        title="Thanks for your submission"
        // description='Bring to the table win-win survival strategies to ensure dotted proactive domination. At the end of the day, going forward, a new normal that has evolved '
      />
      <p className='mr-80 ml-80 text-center'>
      A representative will contact you within 24 hours or the next business day. Thank you for your enquiry.
      </p>
      <Footer footerClass='rs-footer' />
      {/* <SearchModal /> */}
    </React.Fragment>
  );
};

export default Thanks;
